import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { Layout, SEO } from '../components';
import { rem, theme } from '../theme';

const Wrapper = styled.div`
  margin-top: 30px;
  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    margin-top: 0;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  :first-child {
    max-width: 446px;
    font-size: ${rem(10)};

    span {
      padding: 0;
      font-weight: ${theme.fontWeights[700]};
      color: ${theme.colors.brandColor[900]};
    }

    p {
      color: ${theme.colors.font.secondary};
      margin: 50px 0;
    }
  }

  padding: 30px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 668px;

  padding: 30px;
`;

const StyledLink = styled(Link)`
  background-color: ${theme.colors.primary};
  text-align: center;
  width: 121px;
  height: 36px;
  color: white;
  font-weight: ${theme.fontWeights[700]};
  text-decoration: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UnderContruction = () => {
  const underContructionImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "under-construction.png" }) {
        childImageSharp {
          fluid(maxWidth: 668) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Not found" />
      <Wrapper>
        <Box>
          <>
            <span>Whoops...</span>
            <span>
              we’re still working <br /> on this page.
            </span>
          </>
          <p>To make things right we need some time to finish.</p>
          <StyledLink to="">Go Home</StyledLink>
        </Box>
        <ImageWrapper>
          <Img
            fluid={underContructionImage.placeholderImage.childImageSharp.fluid}
          />
        </ImageWrapper>
      </Wrapper>
    </Layout>
  );
};

export default UnderContruction;
