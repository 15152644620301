import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { SEO } from '../components';
import Layout from '../components/Layout/Layout';
import { theme } from '../theme';
import UnderContruction from './undercontruction';

type ImageItem = {
  image: string;
  url: string;
  title: string;
};

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 148px;
`;

const PortfolioContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  width: 100%;
  justify-items: center;
`;

const PortfolioImgBox = styled.div`
  width: 320px;
  height: auto;
  border: 15px solid ${theme.colors.backgroundShapes};
`;

const PortfolioPage = () => {
  const data = useStaticQuery(graphql`
    query descAndImgs {
      dataJson(type: { eq: "portfolio" }) {
        id
        items {
          image
          url
          title
        }
      }
      allFile(filter: { relativeDirectory: { eq: "portfolio" } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              fixed(width: 320) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const ImgDesc = new Map<string, string>();
  data.dataJson.items.forEach((element: ImageItem) => {
    ImgDesc.set(element.image, element.title);
  });

  return (
    <Layout>
      <SEO title="Portfolio" />
      <PageContainer>
        <PortfolioContainer>
          {data.allFile.edges.map((item: any) => {
            const title = ImgDesc.get(item.node.relativePath);
            // TODO: Check fluid version of the image
            return (
              <PortfolioImgBox>
                <Img
                  key={item.node.relativePath}
                  fixed={item.node.childImageSharp.fixed}
                  title={title}
                  className="portfolio-img"
                ></Img>
              </PortfolioImgBox>
            );
          })}
        </PortfolioContainer>
      </PageContainer>
    </Layout>
  );
};

export default UnderContruction;
